<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <Tab :list="tabList" :active="tabActive" @tabChange="tabChange" />

      <!-- 政策 -->
      <IndexPolicy ref="policyChild" sign="policy" 
        remark="企业" zcSign="1" v-show="tabActive === 0"
      />
      <!-- 办事指南 -->
      <IndexDepInterest
        v-show="tabActive === 1"
        :id="getFundInfoId"
        :departId="getFundInfoCode"
        sign="rural"
      />
        <!-- 惠企资金 -->
        <IndexEnterprise
          ref="enterpriseChild"
          sign="enterprise"
          v-show="tabActive === 2"
        />
      <!-- 监督投诉 -->
      <indexHuiqiCapital ref="huiqiCapitalChild" sign="huiqiCapital" 
        remark="监督" zcSign="3" v-if="tabActive === 3"
      />
      <!-- 底部内容 -->
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "../../components/Footer";
import Tab from "./components/Tab";
// 企业
import IndexEnterprise from "./indexChild/indexEnterprise";
// 政策
import IndexPolicy from "./indexChild/indexPolicy";
// 办事指南
import IndexDepInterest from "./indexChild/indexDepInterest";
// 监督投诉
import indexHuiqiCapital from "./indexChild/indexHuiqiCapital.vue";

export default {
  name: "business_development_index",
  components: { Tab, Footer, IndexEnterprise, IndexPolicy, IndexDepInterest, indexHuiqiCapital },
  activated() {},
  data() {
    return {
      // 标签页
      tabList: [
        { name: this.$t('travel.tabList.policy') },
		    { name: this.$t('travel.tabList.interest') },
        { name: this.$t('travel.tabList.huiqi') },
        { name: this.$t('travel.tabList.enterprise') },
      ],
      // 当前选中标签页
      tabActive: 0,
      getFundInfoId: '1',
      getFundInfoCode: ''
    };
  },
  methods: {
    /**
     * 改变选中tab页
     */
    tabChange(index) {
      // console.log(index);
      this.tabActive = index;
    },
  },
};
</script>
<style scoped>
.container {
  /* height: calc(100vh - 97px); */
}
.swiper-box {
  height: 200px;
  background: #fff;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
